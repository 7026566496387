
export const texturePortraitVertices = {
    front: [1.0, 0.5, 0.0, 0.5, 0.0, 0.0, 1.0, 0.0],
    back: [0.0, 1.0, 1.0, 1.0, 0.0, 0.5, 1.0, 0.5],
};

export const textureLandscapeVertices = {
    front: [0.5, 1.0, 1.0, 1.0, 0.5, 0.0, 1.0, 0.0],
    back: [0.0, 1.0, 0.5, 1.0, 0.0, 0.0, 0.5, 0.0],
};

export const stencilOffsetPortraitVertices = {
    front: [0.0, -0.5],
    back: [0.0, 0.5],
};
export const stencilOffsetLandscapeVertices = {
    front: [-0.5, 0.0],
    back: [0.5, 0.0],
};
