import {
    texturePortraitVertices,
    textureLandscapeVertices,
    stencilOffsetPortraitVertices,
    stencilOffsetLandscapeVertices,
} from './vertics';
import {requestAnimFrame, cancelAnimFrame} from '../utils/tick';
import {WebGLUtils} from './helper';

/**
 * VideoShaderRender
 * webgl video shader renderer
 * @param {HTMLElement} container player container element
 * @return {String} stencilOrder stencil relative order  ['front', 'back']
 * @return {String} orientation video orientation ['portrait', 'landscape']
 * @return {Object} pixels texture pixels object https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/texImage2D
 */
class VideoShaderRender {
    container: HTMLElement;
    orientation: 'portrait' | 'landscape';
    stencilOrder: 'front' | 'back';
    // eslint-disable-next-line @typescript-eslint/ban-types
    pixels: TexImageSource;
    canvas!: HTMLCanvasElement;
    gl!: WebGLRenderingContext;
    dpr!: number;
    shaderProgram!: WebGLProgram;
    positionVertices!: Float32Array;
    textureVertices!: Float32Array;
    offsetVertices!: number[];
    ticker!: number;

    vertexFrag = `
  attribute vec4 aVertexPosition;
  attribute vec2 aTextureCoord;
  varying vec2 vTextureCoord;
  varying vec2 vTextureOffsetVector;
  uniform vec2 uTextureOffsetVector;
  uniform vec4 uScale;
  void main () {
    gl_Position = aVertexPosition * uScale;
    vTextureCoord = aTextureCoord;
    vTextureOffsetVector = uTextureOffsetVector;
  }
  `;

    /**

  //gl_FragColor = vec4(texture2D(uSampler, vTextureCoord).rgb, texture2D(uSampler, vTextureCoord+vTextureOffsetVector).r); // rgb + alpha变化
  gl_FragColor = vec4(
    texture2D(uSampler, vec2(0.5 + vTextureCoord.x/2., vTextureCoord.y)).rgb,
    texture2D(uSampler, vec2(vTextureCoord.x/2., vTextureCoord.y)).r
  );

   */

    textureFrag = `
  precision lowp float;
  uniform sampler2D uSampler;
  varying vec2 vTextureCoord;
  varying vec2 vTextureOffsetVector;
  void main () {

    gl_FragColor = vec4(
      texture2D(uSampler, vTextureCoord).rgb,
      texture2D(uSampler, vec2(0.5 + vTextureCoord.x, vTextureCoord.y)).r
    );

  }
  `;

    /**

  gl_FragColor = vec4(
    texture2D(uSampler, vTextureCoord).rgb,
    texture2D(uSampler, vec2(0.5 + vTextureCoord.x/2., vTextureCoord.y)).r
  );
  **/

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(options: {
        container: HTMLElement;
        stencilOrder: 'front' | 'back';
        orientation: 'portrait' | 'landscape';
        pixels: TexImageSource;
    }) {
        this.container = options.container;
        this.orientation = options.orientation;
        this.stencilOrder = options.stencilOrder;
        this.pixels = options.pixels;
        this.init();
    }

    init() {
        this.canvas = this.createCanvas();
        this.gl = this.createGl(this.canvas) as WebGLRenderingContext;
        this.dpr = window.devicePixelRatio || 1;
        this.shaderProgram = this.createProgram()!;

        this.setRenderViewPort();
        this.initVertics();
        this.initVerticsBuffer();
        this.initTextureBuffer();
    }

    setRenderViewPort(width?: number, height?: number) {
        const {container, dpr} = this;
        const viewWidth = width || container.offsetWidth;
        const viewHeight = height || container.offsetHeight;
        const dprX = Math.floor((viewWidth - viewWidth * dpr) / 2);
        const dprY = Math.floor((viewHeight - viewHeight * dpr) / 2);
        this.gl.viewport(dprX, dprY, viewWidth * dpr, viewHeight * dpr);
    }

    initRenderShader() {
        const {gl} = this;

        const vertexShader = WebGLUtils.loadShader(gl, gl.VERTEX_SHADER, this.vertexFrag);
        const fragmentShader = WebGLUtils.loadShader(gl, gl.FRAGMENT_SHADER, this.textureFrag);
        return {
            vertexShader,
            fragmentShader,
        };
    }

    initVertics() {
        this.positionVertices = new Float32Array([-1.0, 1.0, 1.0, 1.0, -1.0, -1.0, 1.0, -1.0]);
        if (this.orientation === 'portrait') {
            this.textureVertices = new Float32Array(texturePortraitVertices[this.stencilOrder]);
            this.offsetVertices = stencilOffsetPortraitVertices[this.stencilOrder];
        } else {
            this.textureVertices = new Float32Array(textureLandscapeVertices[this.stencilOrder]);
            this.offsetVertices = stencilOffsetLandscapeVertices[this.stencilOrder];
        }
    }

    initVerticsBuffer() {
        const aVertexPositionLocation = this.getAttribLocation('aVertexPosition');
        const aTextureCoordLocation = this.getAttribLocation('aTextureCoord');
        const vTextureOffsetVectorLocation = this.getUniformLocation('vTextureOffsetVector');
        const uScaleVectorLocation = this.getUniformLocation('uScale');
        const [stencilOffsetX, stencilOffsetY] = this.offsetVertices;
        WebGLUtils.setShaderBuffer(this.gl, aVertexPositionLocation, this.positionVertices);
        WebGLUtils.setShaderBuffer(this.gl, aTextureCoordLocation, this.textureVertices);
        this.gl.uniform2f(vTextureOffsetVectorLocation, stencilOffsetX, stencilOffsetY);

        this.gl.uniform4fv(uScaleVectorLocation, [1 / this.dpr, 1 / this.dpr, 0.0, 1.0]);
    }

    initTextureBuffer() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {gl, pixels} = this;
        WebGLUtils.createTexure(gl);
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1);

    }

    step = () => {
        this.ticker = requestAnimFrame(this.step);
        this.tick();
    };

    tick() {
        this.draw();
    }

    startTick = () => {
        this.ticker = requestAnimFrame(this.step);
    };

    stopTick = () => {
        cancelAnimFrame(this.ticker);
    };

    draw() {
        const {gl, pixels} = this;
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, pixels);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    }

    createCanvas() {
        const {container} = this;
        const canvasEl = WebGLUtils.createCanvas(container.offsetWidth, container.offsetHeight);
        container.appendChild(canvasEl);
        return canvasEl;
    }

    createGl(canvas: HTMLCanvasElement): RenderingContext {
        const gl = WebGLUtils.getContextWebGL(canvas);
        return gl;
    }

    createProgram() {
        const {vertexShader, fragmentShader} = this.initRenderShader();
        return WebGLUtils.createProgram(this.gl, vertexShader!, fragmentShader!);
    }

    getAttribLocation(name: string) {
        return this.gl.getAttribLocation(this.shaderProgram, name);
    }

    getUniformLocation(name: string) {
        return this.gl.getUniformLocation(this.shaderProgram, name);
    }

    resizeRender(width: number, height: number) {
        this.canvas.width = width;
        this.canvas.height = height;
        this.setRenderViewPort(width, height);
    }

    destroy() {
        this.stopTick();
        this.gl.clear(this.gl.DEPTH_BUFFER_BIT);
    }
}
export default VideoShaderRender;
